  
  #root>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .app>div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  h1 {
    font-family: 'Damion', cursive;
    color: #fff;
    text-shadow: 0px 0px 60px 0px rgba(0,0,0,0.30);
  }
  
  h2 {
    color: #fff;
  }
  
  .swipe {
    position: absolute;
  }
  
  .cardContainer {
    width: 90vw;
    max-width: 260px;
    height: 300px;
  }
  
  .card {
    position: relative;
    background-color: #fff;
    width: 100vw;
    max-width: 300px;
    height: 300px;
    box-shadow: 0px 0px 60px 0px rgba(0,0,0,0.30);
    border-radius: 20px;
    background-size: cover;
    background-position: center;
  }
  
  .cardContent {
    width: 100%;
    height: 100%;
  }
  
  .swipe:last-of-type {
  
  }
  
  .card h3 {
    position: absolute;
    bottom: 0;
    margin: 10px;
    color: #fff;
  }
  

  .infoText {
    width: 100%;
    height: 28px;
    justify-content: center;
    display: flex;
    color: #fff;
    animation-name: popup;
    animation-duration: 800ms;
  }
  
  .buttons {
    margin: 20px;
    display: flex;
  }
  
  .buttons button {
    flex-shrink: 0;
    padding: 10px;
    border-radius: 5px;
    border: none;
    color: #fff;
    font-size: 18px;
    background-color: #9198e5;
    transition: 200ms;
    margin: 0 10px;
    font-weight: bolder;
    width: 160px;
    box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.10);
  }
  
  .buttons button:hover {
    transform: scale(1.05);
  }
  
  @keyframes popup {
    0%   { transform: scale(1,1) }
    10%  { transform: scale(1.1,1.1) }
    30%  { transform: scale(.9,.9) }
    50%  { transform: scale(1,1) }
    57%  { transform: scale(1,1) }
    64%  { transform: scale(1,1) }
    100% { transform: scale(1,1) }
  }